<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  created() {
    // console.log("acc: ", this.getAccessToken);
  },
  computed: {
    ...mapGetters("deo", ["getAccessToken"]),
  },
};
</script>

<style lang="scss" scoped>
</style>