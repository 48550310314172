import Vue from 'vue';
import VueRouter from 'vue-router';
import SignIn from '../views/Authentication/SignIn';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'signIn',
    meta: {
      layout: 'auth-layout',
      title: 'Sign In',
    },
    component: SignIn,
  },
  {
    path: '/sign-up',
    name: 'signUp',
    meta: {
      layout: 'auth-layout',
      title: 'Sign Up',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/Authentication/SignUp.vue'
      ),
  },
  {
    path: '/deo',
    name: 'deo',
    meta: {
      layout: 'layout-deo',
      requiresAuth: true,
      title: 'Deo',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Deo.vue'),
  },
  {
    path: '/register-deo',
    name: 'registerDeo',
    meta: {
      layout: 'layout-deo',
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/deo/registerDeo.vue'),
  },
  {
    path: '/deo-details/:showComponent?',
    name: 'deoDetails',
    props: true,

    meta: {
      layout: 'layout-deo',
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/deo/DeoDetails.vue'),
  },
  {
    path: '/pending-verifications/:showComponent?',
    name: 'PendingVerifications',
    props: true,
    meta: {
      layout: 'layout-deo',
      requiresAuth: true,
      title: 'Pending Verifications',
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/deo/pendingVerifications.vue'
      ),
  },
  {
    path: '/menus/:showComponent?',
    name: 'Menus',
    props: true,
    meta: {
      layout: 'layout-deo',
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/RolesnPermissions/Menus.vue'
      ),
  },
  {
    path: '/roles/:showComponent?',
    name: 'Roles',
    props: true,
    meta: {
      layout: 'layout-deo',
      requiresAuth: true,
      title: 'Roles',
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/RolesnPermissions/Roles.vue'
      ),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      layout: 'layout-deo',
      requiresAuth: true,
      title: 'Dashboard',
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
  },
  {
    path: '/branches/:showComponent?',
    name: 'Branches',
    props:true,
    meta: {
      layout: 'layout-deo',
      requiresAuth: true,
      title: 'Branches',
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Branches.vue'),
  },
  {
    path: '/business/:showComponent?',
    name: 'Business',
    meta: {
      layout: 'layout-deo',
      requiresAuth: true,
      title: 'Business',
    },
    props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Business.vue'),
  },
  {
    path: '/user/:showComponent?',
    name: 'User',
    meta: {
      layout: 'layout-deo',
      requiresAuth: true,
      title: 'User',
    },
    props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/User.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  const DEFAULT_TITLE = '';
  const PROJECT_NAME = 'eyekyc';
  Vue.nextTick(() => {
    document.title = `${PROJECT_NAME}-${
      to.meta.title ? to.meta.title : DEFAULT_TITLE
    } `;
  });
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // console.log(store.getters["deo/getAccessToken"]);
    if (!store.getters['auth/getAccessToken']) {
      router.replace('/');
    } else {
      next();
    }
  } else {
    // if (store.getters['auth/get_access_token']) {
    //     router.replace('/Dashboard')
    //   } else {
    //     next()
    //   }
  }
  next();
});

export default router;
