<template>
  <div>
    <v-container>
      <!-- <v-row>
           <v-col  v-if="this.getRegistrationSnack"  cols="12" sm="12" md="12" lg="12" xl="12">
            <v-alert v-if="this.getRegistrationSnack" :value="alert" dense outlined class="showAlert mx-15" type="success">
              Registration Successfull, kindly check your email for activation.
            </v-alert>
           </v-col>

           <v-col  v-if="this.apiError"  cols="12" sm="12" md="12" lg="12" xl="12">
              <v-alert dense outlined class="showAlert mx-15" type="error">
                {{apiError}}
              </v-alert>
          </v-col>
          </v-row> -->

      <v-row style="height: 100vh" class="d-flex align-center">
        <v-col cols="12" sm="8" md="6" lg="5" xl="5" class="mx-auto">
          <v-card elevation="1">
            <v-row>
              <v-col
                v-if="this.getRegistrationSnack"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <v-alert
                  v-if="this.getRegistrationSnack"
                  :value="alert"
                  dense
                  outlined
                  class="showAlert mx-15"
                  type="success"
                >
                  Account Created Successfully, Visit your email to set password
                </v-alert>
              </v-col>

              <!-- <v-col
                v-if="this.getTokenSnack"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <v-alert
                  v-if="this.getTokenSnack"
                  :value="alert"
                  dense
                  outlined
                  class="showAlert mx-15"
                  type="success"
                >
                  Token Expired, Login again or use another email.
                </v-alert>
              </v-col> -->

              <v-col
                v-if="this.apiError"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <v-alert dense outlined class="showAlert mx-15" type="error">
                  {{ apiError }}
                </v-alert>
              </v-col>
            </v-row>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12"> </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h1 class="font-weight-light mb-5">Login</h1>
                  <v-text-field
                    v-model="$v.email.$model"
                    label="E-mail"
                    required
                    filled
                    hide-details="auto"
                    :error-messages="emailErrors"
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-text-field
                    v-model="$v.password.$model"
                    :error-messages="passwordErrors"
                    label="Password"
                    required
                    filled
                    hide-details="auto"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="mx-2">
              <v-btn
                @click="SigningIn"
                :loading="onLogin"
                depressed
                color="primary"
                block
                class="mb-3"
                :disabled="
                  password == '' ||
                  email == '' ||
                  emailErrors.length > 0 ||
                  passwordErrors.length > 0
                "
              >
                Login
              </v-btn>
            </v-card-actions>
            <v-col>
              <!-- <v-col cols="12" class="font-weight-light" > -->
              <!-- <p class=" subtitle text-center" style="font-size:13px">By logging in our using this app, you are indicatiing that you have read and agree to the <a>Terms of Service</a> and <a>Privacy Policy.</a></p> -->
              <!-- </v-col> -->
              <v-col class="pb-0">
                <p class="text-center">
                  Don't have an account?<v-btn
                    class="mr-5"
                    color="primary"
                    text
                    :to="{ name: 'signUp' }"
                    >Register Now</v-btn
                  >
                </p>
              </v-col>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  data() {
    return {
      show1: false,
      onLogin: false,
      email: "",
      password: "",
      alert: true,
      apiError: null,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },
  async mounted() {
    await setTimeout(() => {
      this.apiError = null;
      this.alert = false;
      // this.$store.commit("auth/setRegistrationSnack", false);
    }, 5000);
  },
  created(){
    
  },
  beforeDestroy() {
    if (this.getRegistrationSnack == true) {
      this.$store.commit("auth/setRegistrationSnack", false);
      this.$store.commit("auth/setTokenSnack", false);
    }
  },
  computed: {
    ...mapGetters("auth", ["getRegistrationSnack", "getTokenSnack"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Password must be at least 6 characters");
      !this.$v.password.required && errors.push("Password is required.");
      return errors;
    },
  },
  methods: {
    ...mapActions("auth", ["login"]),
    ...mapActions("deo", ["pendingDeoVerifications"]),
    SigningIn() {
      this.$v.$touch();
      this.onLogin = true;
      if (this.$v.$anyError) return;
      else {
        this.onLogin = true;
        let obj = {
          email: this.email,
          password: this.password,
        };
        this.login(obj)
          .then((res) => {
            // console.log(res, ":login:");
            if(res.data.status === 'Already Logged In'){
            this.onLogin = false;
              this.apiError = 'User Already Logged In, kinldy use another account.'
            }
            else{
            this.onLogin = false;
            this.$store.commit("auth/setLoggedIn", true);
            this.$store.commit("auth/setUserDetails", res.data);
            this.$router.push({
              name: "dashboard",
            });
            this.pendingDeoVerifications().then(res => {
            this.pendingCount = res.data.message.length
            this.$store.commit("deo/setPendingCounter", this.pendingCount);
            // console.log(this.pendingCount);
            }).catch(e => {
              console.log(e);
            })
            }
          })
          .catch((e) => {
            console.log(e, ":error");
            this.apiError = e;
            this.onLogin = false;
            setTimeout(() => {
              this.apiError = null;
            }, 5000);
          })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>