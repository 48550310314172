<template>
  <v-app>
    <!-- <v-app-bar class="app-header" color="#3D5AFE" dark app>
      <v-container fluid>
        <div class="d-flex align-center">
          <v-app-bar
            absolute
            color="primary"
            elevate-on-scroll
            scroll-target="#scrolling-techniques-7"
          >
            <v-btn
              :to="{ name: 'dashboard' }"
              rounded
              text
              class="text-capitalize"
              >Dashboard</v-btn
            >
            <v-btn v-if="getUserRole == 'deo'" :to="{ name: 'deo' }" rounded text class="text-capitalize"
              >DEO</v-btn
            >
            <v-btn v-if="getUserRole == 'superuser'" :to="{ name: 'registerDeo' }" rounded text class="text-capitalize"
              >Create Deo</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn @click="logoutUser" rounded text class="text-capitalize red"
              >Logout</v-btn
            >
          </v-app-bar>
        </div>
      </v-container>
    </v-app-bar> -->
<v-app-bar app  clipped-right clipped-left dark color="primary">
      <v-toolbar-title>
        <a href="">
          <img
            src="@/assets/logo/AppLogo.svg"
            alt=""
            width="96"
            class="mt-2"
            height="38"
          />
        </a>
      </v-toolbar-title>
      <v-divider vertical class="ml-4 mr-0"></v-divider>
      <div style="min-width:80px !important;max-width:190px !important;">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{getUserDetails.username}}</v-list-item-title>
            <v-list-item-subtitle>DEO Status</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
      <v-divider vertical class="ml-0 mr-1"></v-divider>
        <v-btn :to="{ name: 'dashboard' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-desktop-mac</v-icon> Dashboard</v-btn>
      <v-btn v-if="getUserDetails.role == 'DEO'" :to="{ name: 'deo' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-keyboard</v-icon> DEO</v-btn
      >
      <v-btn v-if="getUserDetails.role == 'DEO'" :to="{ name: 'PendingVerifications' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-shield-alert-outline</v-icon>Pending Verifications</v-btn
      >
      <v-btn v-if="getUserDetails.role == 'SuperAdmin'" :to="{ name: 'registerDeo' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-keyboard</v-icon> Create DEO</v-btn
      >
          <v-btn v-if="getUserDetails.role == 'SuperAdmin'" :to="{ name: 'Roles' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-human-edit</v-icon>Roles</v-btn
      >
       <v-btn  :to="{ name: 'Business' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-human-edit</v-icon>Business</v-btn
      >
      <!-- <v-btn :to="{ name: 'Menus' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-menu-open</v-icon>Menu</v-btn
      > -->


  <v-spacer></v-spacer>
    <v-btn icon dark class="mr-2">
        <v-badge
          color="green"
          overlap
          :content="getPendingCounter == 0 ? '0' : getPendingCounter"
        >
          <v-icon>mdi-bell-outline</v-icon>
        </v-badge>
    </v-btn>
    <v-tooltip bottom>
    <template v-slot:activator="{ on }">
    <v-btn @click="logoutUser" v-on="on" icon>
    <v-icon>mdi-logout-variant</v-icon> </v-btn>
    </template>
    <span>Logout</span>
     </v-tooltip>

      <!-- <v-btn @click="logoutUser" rounded text class="text-capitalize red"
        >Logout</v-btn
      > -->
    </v-app-bar>
    <v-main class="main-background">
      <router-view> </router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import config from '../config/config.js';

export default {
  data(){
    return {
      status: "disconnect",
      socket: "",
      pendingCount: 0,
      timeout: null,
    }
  },
  computed: {
    ...mapGetters("auth",["getUserRole", "getUserDetails", "getUserId"]),
    ...mapGetters("deo",["getPendingCounter"]),
  },
  created(){
    this.connected();
    this.getAllPendingVerification();
  },
  beforeDestroy(){
    this.socket.close();
    clearInterval(this.timeout);
  },
  methods: {
    ...mapActions("deo",["updateDeoStatus", "pendingDeoVerifications"]),
    connected(){
      this.socket = new WebSocket(
        `${config.seck}/ws/deo-status/${this.getUserId}/`
      );
      this.socket.onopen = () => {
        this.status = "login socket connected";
        console.log(this.status);
      };
      this.socket.onmessage = async(data) => {
        console.log(data.data);
        if(data.data === 'Token Destroyed'){
          this.$router.push('/');
          this.$store.commit("auth/setTokenSnack", true);
        }
      }
    },
    getAllPendingVerification(){
      const call = () => this.pendingDeoVerifications().then(res => {
      this.pendingCount = res.data.message.length;
      this.$store.commit("deo/setPendingCounter", this.pendingCount);
      if(res.data.message.length != 0){
        // console.log('ring ring ring');
        // var audio = new Audio("https://kycadmin.devco.pk/fdb.mp3");
        // audio.play();
      }
      console.log(this.pendingCount);
      }).catch(e => {
        console.log(e);
      })
      this.timeout = setInterval(() => {
        call()
      }, 30000);
      
    },
    async logoutUser() {
      await clearInterval(this.timeout);
      await this.$store.commit("auth/setUserDetails", []);
      await this.$store.commit("auth/setLoggedIn", false);
      await this.$router.push({
        name: "signIn",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-topNav {
  background-color: white !important;
  color: #FF5722;
      // opacity:0.18 !important;
}
.main-background {
  background-color: #f5f6fa;
  
}
</style>