import {private_url, public_url} from '../axios'
import createPersistedState from "vuex-persistedstate";

const plugins = [createPersistedState()];
const state = {
    userid:""
};
const mutations = {
    setUserId(state,payload){
    state.userid = payload
   }
};
const getters = {
    getBranchesId(state){
        return state.userid
    }
};
const actions = {
    allUser({},payload){
        return private_url.get("/api-business/business_user/id="+payload+"/")
    },
    activUser({},payload){
        return private_url.get("/api-business/business_user/active/id="+payload+"/")
    },
    inActivUser({},payload){
        return private_url.get("/api-business/business_user/inactive/id="+payload+"/")
    },
    deactivateUser({},payload){
        console.log("dnvbdmnfbndvdf",payload)
        return private_url.delete("/api-business/business_user/"+payload+"/")
    },
    activateUser({},payload){
        console.log("dnvbdmnfbndvdf",payload)
        return private_url.patch("/api-business/business_user/"+payload+"/")
    },
    viewUser({state}){
        console.log("dnvbdmnfbndvdf",state.userid)
        return private_url.get("/api-business/business_user/"+state.userid+"/")
    },
};

export default {
    namespaced: true,
    state,
    mutations, 
    getters,
    actions,
    plugins
}