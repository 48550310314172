import config from '../../config/config.js'
import axios from 'axios'
import {private_url, public_url} from '../axios'
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const state = {
    deoStatus: '',
    singleDeoDetails: null,
    pendingVerifications:[],
    deoVerificationDetails: [],
    pendingCounter: 0,
    // businessId: null,
    // branchId: null,
};

const mutations = {
    setDeoStatus: (state, payload) => (state.deoStatus = payload),
    setSingleDeoDetails: (state, payload) => (state.singleDeoDetails = payload),
    setPendingVerifications: (state, payload) => (state.pendingVerifications = payload),
    setDeoVerificationDetails: (state, payload) => (state.deoVerificationDetails = payload),
    setPendingCounter: (state, payload) => (state.pendingCounter = payload)
    // setBusinessId: (state, payload) => (state.businessId = payload),
    // setBranchId: (state, payload) => (state.branchId = payload),
};

const getters = {
    getDeoStatus: (state) => state.deoStatus,
    getSingleDeoDetails: (state) => state.singleDeoDetails,
    getPendingVerifications: (state) => state.pendingVerifications,
    getDeoVerificationDetails: (state) => state.deoVerificationDetails,
    getPendingCounter: (state) => state.pendingCounter,
    // getBranchId: (state) => state.branchId,
    // getBusinessId: (state) => state.businessId,
};

const actions = {
    getAllDEOInfo({commit}, status){
        return private_url.get(`/api-admin/deos_status/`, status) 
    },
    updateDeoStatus({commit}, status){
        return private_url.post(`/api-deo/deo-status/`, status) 
    },
    deoCurrentStatus({commit}){
        return private_url.get('/api-deo/check-deo-status/')
    },
    storeUserData({commit}, data){
        console.log(data, 'Store Data:')
        return private_url.post('/api-deo/store-data/', data)
    },
    storeAddress({commit}, data){
        console.log(data, 'Store Data:')
        return private_url.post('/api-deo/store-address/', data)
    },
    register({commit}, data){
        // console.log(data, "register:")
        return private_url.post(`/api-deo/register/`, data)
    },
    pendingDeoVerifications({}, data){
        return private_url.get(`/api-deo/pending/`, data)
    },
    addressPendingVerifications({}){
        return private_url.get('/api-deo/pending-address/')
    },
    singlePendingDeoVerification({}, data){
        return private_url.post('/api-deo/verify-pending/', data)
    },
    singleAddressDeoVerification({}, data){
        return private_url.post(`/api-deo/verify-pending-address/`, data)
    },
    verifyPendingRequest({}, data){
        return private_url.post('/api-deo/submit-pending/', data)
    },
    verifyAddressRequest({}, data){
        return private_url.post(`/api-deo/submit-pending-address/`, data)
    },
    
    deoDetailsFetch({commit}, data){
        return private_url.post('/api-deo/deo-details/', data)
    },
    singleVerificationDetail({},data){
        return private_url.get(`/api-deo/result/${data}/`)
    },
    deactivateDeo({}, data){
        return private_url.delete(`/api-deo/deactivate/${data}/`)
    },
    activateDeo({}, data){
        return private_url.get(`/api-deo/reactivate/${data}/`)
    },
    showDeactivatedDeo({}){
        return private_url.get(`/api-admin/inactive_deos/`)
    },
};

export default {
    namespaced: true,
    state,
    mutations, 
    getters,
    actions,
    plugins
}