import {private_url, public_url} from '../axios'
import createPersistedState from "vuex-persistedstate";

const plugins = [createPersistedState()];
const state = {
    businessId:""
};
const mutations = {
   setBusinessId(state,payload){
    state.businessId = payload
   }
};
const getters = {
    getBusinessId(state){
        return state.businessId
    }
};
const actions = {
    allBusiness({},){
        return private_url.get("/api-business/all-business")
    },
    activBusiness({},){
        return private_url.get("/api-business/active-business/")
    },
    inActivBusiness({},){
        return private_url.get("/api-business/inactive-business/")
    },
    deactivateBusiness({},payload){
        console.log("dnvbdmnfbndvdf",payload)
        return private_url.delete("/api-business/business/"+payload+"/")
    },
    activateBusiness({},payload){
        console.log("dnvbdmnfbndvdf",payload)
        return private_url.patch("/api-business/business/"+payload+"/")
    },
    viewBussiness({state}){
        console.log("dnvbdmnfbndvdf",state.businessId)
        return private_url.get("/api-business/business/"+state.businessId+"/")
    },
};

export default {
    namespaced: true,
    state,
    mutations, 
    getters,
    actions,
    plugins
}