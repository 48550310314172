import {private_url, public_url} from '../axios'
import createPersistedState from "vuex-persistedstate";

const plugins = [createPersistedState()];
const state = {
    branchid:""
};
const mutations = {
    setBranchesId(state,payload){
    state.branchid = payload
   }
};
const getters = {
    getBranchesId(state){
        return state.branchid
    }
};
const actions = {
    allBranches({},payload){
        return private_url.get("/api-business/all-branches/id="+payload+"/")
    },
    activBranches({},payload){
        return private_url.get("/api-business/branch/id="+payload+"/")
    },
    inActivBranches({},payload){
        return private_url.get("/api-business/inactive-branches/id="+payload+"/")
    },
    deactivateBranches({},payload){
        console.log("dnvbdmnfbndvdf",payload)
        return private_url.delete("/api-business/branch/"+payload+"/")
    },
    activateBranches({},payload){
        console.log("dnvbdmnfbndvdf",payload)
        return private_url.patch("/api-business/branch/"+payload+"/")
    },
    viewBranches({state}){
        console.log("dnvbdmnfbndvdf",state.branchid)
        return private_url.get("/api-business/branch/"+state.branchid+"/")
    },
};

export default {
    namespaced: true,
    state,
    mutations, 
    getters,
    actions,
    plugins
}