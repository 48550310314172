import Vue from 'vue'
import Vuex from 'vuex'
import deo from './modules/deo'
import auth from './modules/auth'
import roleMenuPermission from './modules/roleMenuPermission'
import business from './modules/business'
import branches from './modules/branches'
import user from './modules/user'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    deo,
    auth,
    roleMenuPermission,
    business,
    branches,
    user
  }
})
