import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import layoutDeo from '@/layout/layout-deo.vue';
import authLayout from '@/layout/auth-layout.vue';
import VarificationLayout from '@/layout/VerificationLayout.vue';
const ImageZoom = require('vue-image-zoomer').default;

Vue.config.productionTip = false

Vue.component('image-zoom', ImageZoom);
Vue.component('layout-deo', layoutDeo);
Vue.component('auth-layout', authLayout);
Vue.component('VarificationLayout', VarificationLayout);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
