<template>
  <v-app>
     <v-app-bar color="primary"  class="elevation-2"  app clipped-right clipped-left dark>
     <v-app-bar-nav-icon
        @click.stop="sheet = !sheet"
        class="d-flex d-lg-none"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="d-none d-lg-flex ml-n4">
        <a aria-label="dashboardlogo">
          <a href="">
          <img
            src="@/assets/logo/AppLogo.svg"
            alt=""
            width="96"
            class="mt-2"
            height="38"
          />
        </a>
        </a>
      </v-toolbar-title>
      <v-divider vertical class="ml-4 mr-0"></v-divider>
      <div style="min-width:80px !important;max-width:190px !important;">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{getUserDetails.username}}</v-list-item-title>
            <v-list-item-subtitle>DEO Status </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
      <v-divider vertical class="ml-4 d-none d-lg-flex"></v-divider>

      <div class="ml-3 d-none d-lg-flex">
        <v-btn :to="{ name: 'dashboard' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-desktop-mac</v-icon> Dashboard</v-btn>

      <v-btn v-if="getUserDetails.role == 'deo'" :to="{ name: 'deo' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-keyboard</v-icon> DEO</v-btn>
      <v-btn v-if="getUserDetails.role == 'deo'" :to="{ name: 'PendingVerifications' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-shield-alert-outline</v-icon>Pending Verifications</v-btn>
      <v-btn v-if="getUserDetails.role == 'superuser'" :to="{ name: 'registerDeo' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-keyboard</v-icon> Create Deo</v-btn >
      <v-btn v-if="getUserDetails.role == 'superuser'" :to="{ name: 'Roles' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-human-edit</v-icon>Roles</v-btn>
      </div>
  <v-spacer></v-spacer>
    <v-btn icon class="mr-2">
        <v-badge
          color="green"
          overlap
          :content="getPendingCounter == 0 ? '0' : getPendingCounter"
        >
          <v-icon>mdi-bell-outline</v-icon>
        </v-badge>
    </v-btn>
      <v-tooltip bottom>
      <template v-slot:activator="{ on }">
      <v-btn @click="logoutUser" icon dark v-on="on">
      <v-icon color="white">mdi-logout-variant</v-icon>  </v-btn>
      </template>
      <span>Logout</span>
      </v-tooltip>
      <!-- <v-btn @click="logoutUser" rounded text class="text-capitalize red"
        >Logout</v-btn
      > -->
    </v-app-bar>
    <!--  -->

    <v-main class="main-background">
      <router-view> </router-view>
    </v-main>
    <!-- bottom sheet -->
    <v-bottom-sheet v-model="sheet">
      <v-list nav dense rounded tile>
        <!-- <v-list-item>
          <v-list-item-content>
            <a href="">
              <img
                 src="@/assets/logo/AppLogo.svg"
                alt=""
                width="140"
                class=""
                height="40"
              />
            </a>
          </v-list-item-content>
        </v-list-item> -->
          <v-list-item-group color="primary">
            <!-- 
              <v-btn :to="{ name: 'dashboard' }" class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-desktop-mac</v-icon> Dashboard</v-btn>

      <v-btn  class="text-capitalize ml-1 mr-1" active-class="btn-topNav" text
        ><v-icon left>mdi-human-edit</v-icon>Roles</v-btn>
             -->
          <v-list-item link :to="{ name: 'dashboard' }" @click="sheet = false">
            <v-list-item-avatar size="30">
            <v-avatar color="primary" class="white--text">D</v-avatar>
            </v-list-item-avatar>

            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item link v-if="getUserDetails.role == 'deo'" :to="{ name: 'deo' }" @click="sheet = false">
            <v-list-item-avatar size="30">
            <v-avatar color="primary" class="white--text">D</v-avatar>
            </v-list-item-avatar>
           <v-list-item-title>DEO</v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item link v-if="getUserDetails.role == 'deo'" :to="{ name: 'PendingVerifications' }" @click="sheet = false">
            <v-list-item-avatar size="30">
            <v-avatar color="primary" class="white--text">P</v-avatar>
            </v-list-item-avatar>
           <v-list-item-title>Pending Verifications</v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item link v-if="getUserDetails.role == 'superuser'" :to="{ name: 'registerDeo' }" @click="sheet = false">
            <v-list-item-avatar size="30">
            <v-avatar color="primary" class="white--text">C</v-avatar>
            </v-list-item-avatar>
           <v-list-item-title>Create Deo</v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item link v-if="getUserDetails.role == 'superuser'" :to="{ name: 'Roles' }" @click="sheet = false">
            <v-list-item-avatar size="30">
            <v-avatar color="primary" class="white--text">R</v-avatar>
            </v-list-item-avatar>
           <v-list-item-title>Roles</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <!--  -->
       
      </v-list>
    </v-bottom-sheet>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import config from '../config/config.js';

export default {
  data(){
    return {
      status: "disconnect",
      socket: "",
      pendingCount: 0,
      timeout: null,
      sheet: false,
    }
  },
  computed: {
    ...mapGetters("auth",["getUserRole", "getUserDetails", "getUserId"]),
    ...mapGetters("deo",["getPendingCounter"]),
  },
  created(){
    this.connected();
    this.getAllPendingVerification();
  },
  beforeDestroy(){
    this.socket.close();
    clearInterval(this.timeout);
  },
  methods: {
    ...mapActions("deo",["updateDeoStatus", "pendingDeoVerifications"]),
    connected(){
      this.socket = new WebSocket(
        `${config.seck}/ws/deo-status/${this.getUserId}/`
      );
      this.socket.onopen = () => {
        this.status = "login socket connected";
        console.log(this.status);
      };
      this.socket.onmessage = async(data) => {
        console.log(data.data);
        if(data.data === 'Token Destroyed'){
          this.$router.push('/');
          this.$store.commit("auth/setTokenSnack", true);
        }
      }
    },
    getAllPendingVerification(){
      const call = () => this.pendingDeoVerifications().then(res => {
      this.pendingCount = res.data.message.length;
      this.$store.commit("deo/setPendingCounter", this.pendingCount);
      if(res.data.message.length != 0){
        // console.log('ring ring ring');
        // var audio = new Audio("https://kycadmin.devco.pk/fdb.mp3");
        // audio.play();
      }
      console.log(this.pendingCount);
      }).catch(e => {
        console.log(e);
      })
      this.timeout = setInterval(() => {
        call()
      }, 30000);
      
    },
    async logoutUser() {
      await clearInterval(this.timeout);
      await this.$store.commit("auth/setUserDetails", []);
      await this.$store.commit("auth/setLoggedIn", false);
      await this.$router.push({
        name: "signIn",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-topNav {
  background-color: white !important;
  color: #FF5722;
      // opacity:0.18 !important;
}
.main-background {
  background-color: #f5f6fa;
  
}
</style>