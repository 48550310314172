import config from '../../config/config.js'
import axios from 'axios'
import {private_url, public_url} from '../axios'
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];

const state = {
    registrationSnack: null,
    loggedIn: false,
    userDetails: [],
    tokenSnack: null,
};

const mutations = {
    setRegistrationSnack: (state, payload) => (state.registrationSnack = payload),
    setLoggedIn: (state, payload) => (state.loggedIn = payload),
    setUserDetails: (state, payload) => (state.userDetails = payload),
    setTokenSnack: (state, payload) => (state.tokenSnack = payload)
};

const getters = {
    getRegistrationSnack: (state) => state.registrationSnack,
    getAccessToken: (state) => state.userDetails.access,
    getLoggedIn: (state) => state.loggedIn,
    getUserId: (state) => state.userDetails.user_id,
    getUserRole: (state) => state.userDetails.role,
    getUserDetails: (state) => state.userDetails,
    getTokenSnack: (state) => state.tokenSnack,
};

const actions = {
    register({commit}, data){
        // console.log(data, "register:")
        return public_url.post(`/api-admin/register/`, data)
    },
    login({commit}, data){
        // console.log(data, "login:")
        return public_url.post(`/api-admin/login/`, data)
    }
};

export default {
    namespaced: true,
    state,
    mutations, 
    getters,
    actions,
    plugins
}